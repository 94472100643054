.Course {
  height: 100vh;
  width: 100%;
  color: #747474;
  position: relative;
  background-color: white;
  
  .headerText {
    padding-top: 220px;
    text-align: center;
    margin: auto;
    width: 650px;

    @media (max-width: 720px) {
      padding-top: 100px;
      padding-left: 0;
      width: 92%;
      margin-left: 5%;
    }
  }

  h3 {
    margin-top: 10px;
    color: #747474;
    font-size: 28px;
    padding-bottom: 5px;
    @media (max-width: 720px) {
      font-size: 24px;
    }

  }

  .homeButtons {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    justify-content: center;
    padding-top: 25px;

    @media (max-width: 720px) {
      flex-direction: column;
    }

    .homeButtonLink{
      @media (max-width: 720px) {
        width: 230px;
        margin: 0 auto;
      }
      img.number {
        margin-left: 122px;
        margin-bottom: 5px;
        height: 20px;
        @media (max-width: 720px) {
          display: none;
        }
      }
    }

    img {
      height: 150px;
      margin-bottom: 20px;

      @media (max-width: 720px) {
        height: 80px;
        margin-left: 0;
        margin-right: 20px;
        margin-bottom: 0;
      }

    }

    .link {
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 50px 10px 50px;
      cursor: pointer;

      @media (max-width: 720px) {
        flex-direction: row;
      }

      .homeLabels {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

}

