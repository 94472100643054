.DoOnePractice {
  height: 100vh;
  text-align: left;
  color: #80ac3c;
  margin-left: 370px;
  font-size: 16px;

  .mobileOnly {
    p, h2, h3 {
      text-align: left;
      margin-bottom: 15px;
    }
    
    h3 {
      font-size: 18px;
    }
    text-align: left;
    @media (min-width: 720px) {
      display: none;
    }
  }

  .practice {
    font-weight: bold;
    @media (min-width: 720px) {
      margin-left: 5px;
      margin-top: 87px;
      font-size: 20px;
    }
  }

  .question {
    padding-top: 10px;
    padding-left: 5px;

    @media (max-width: 720px) {
      padding-left: 0px;
      padding-top: 10px;
    }
  }

  .questionText {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 0px
  }

  .questionPreface {
    font-weight: bold;
    display: none;
  }

  .hint {
    margin-top: 5px;
    color: grey;
    font-size: 14px;
  }

  a {
    color: #74acc4;
    font-weight: bold;
  }

  textarea {
    margin-top: 5px;
    border: 1px solid black;
    width: 300px;
  }


  .arrow-right {
    margin-left: 5px;
    width: 0;
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid #80ac3c;
  }

  .arrow-left {
    margin-right: 5px;
    width: 0;
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid #80ac3c;
  }

  .next.hideAnswer {
    margin-top: 10px;
    margin-left: 208px;
    margin-bottom: 10px;
  }

  .hideAnswer {
    margin-top: 5px;
    margin-left: 192px;
    font-weight: bold;
    font-size: 13px;
    color: #80ac3c;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: justify;
    background: white;
  }

  .answerBlob {

    p {
      margin-bottom: 5px;
    }

  }

  .showAnswer {
    color: grey;

    ol {
      list-style-type: lower-alpha;
      margin-left: 18px;

    }

    li {
      margin-bottom: 5px;
    }

    ul {
      list-style: disc;
      margin-left: 20px;

      ul {
        list-style: circle;
      }
    }

    .answer {
      p {
        font-weight: bold;
      }

      font-weight: bold;
      margin-top: 10px; 
      margin-bottom: 10px; 
    }

    .prevNext {
      display: flex;
      flex-direction: row;
      align-items: center;

      .next {
        margin-right: 0;
      }

      .next.false {
        margin-left: 257px;
      }

      .prev {
        margin-left: 0;
        margin-top: 0;
      }
    }

    .showShowReferences {
      margin-top: 15px;
      font-weight: bold;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      cursor: pointer;
    
      .arrow-right {
        margin-left: 5px;
        margin-bottom: 0px;
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 10px solid grey;
      }
      
      .arrow-down {
        width: 0; 
        height: 0; 
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid grey;
        margin-right: 2px;
      }
    }

    .references {
      margin-top: 15px;
      p {
        font-weight: bold;
        margin-bottom: 10px; 
      }

      a {
        font-weight: normal;
      }

      ol {
        list-style-type: decimal;
        margin-left: 17px;
      }

      li {
        margin-bottom: 5px;
      }


    }
  }


  @media (max-width: 720px) {
    margin-left: 10px; 
  }
}

