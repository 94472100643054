#TeachOne {
  height: 100%;
  color: black;
  width: 100%;

  .teachContent {
    display: flex;
    flex-direction: column;
      @media (max-width: 720px) {
        margin-top: 120px;
        height:  calc(100% - 31px);
      }
  }

  .desktopOnly {
    @media (max-width: 720px) {
      display: none;
    }
  }

  .mobileOnly {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media (min-width: 720px) {
      display: none;
      height: 0;
    }

    color: #d85c3c;
    font-size: 24px;
    font-weight: bold;

    .mobileHeaderText {
      margin-top: 120px;
      font-size: 14px;
      margin-bottom: 40px;
    }
    #teachOneIcon {
      margin-top: 10px;
      height: 80px;
    }

    img {
      height: 80px;
    }
  }

  .mobileDots {
    @media (min-width: 720px) {
      display: none;
    }
  }

  .teachLinks {
    color: #d85c3c;
    margin-top: 45px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 380px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    @media (max-width: 720px) {
      margin-top: 0;
      margin-left: 0;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .teachLink {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      @media (max-width: 720px) {
        font-size: 16px;
        margin: 0 25px;
      }

      @media (max-width: 360px) {
        font-size: 12px;
        margin: 0 23px;
      }

      p.narrow {
        text-align: center;
        width: 100px;
      }
    }

    img {
      height: 80px;
      margin: 15px 30px;

      @media (max-width: 720px) {
        height: 70px;
        margin: 0 0px;
      }

      @media (max-width: 360px) {
        height: 60px;
        margin: 0 0px;
      }

    }
  }
}

