#DoOne {
  height: 100vh;
  width: 100%;

  .doContent {
    @media (max-width: 720px) {
      margin-top: 110px;
    }
  }

  .mobileOnly {
    text-align: center;
    @media (min-width: 720px) {
      display: none;
    }

    display: block;
    margin-top: 130px;
    color: #80ac3c;
    font-size: 24px;
    font-weight: bold;
    img {
      height: 80px;
    }
  }

  .doLinks {
    color: #80ac3c;
    font-weight: bold;
    font-size: 15px;
    margin-left: 350px;
    margin-top: 42px;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 720px) {
      margin-left: 0;
      text-align: center;
      align-items: center;
      flex-direction: column;
      margin-top: 0px;

    }

    .mobileDots {
      display: none;
      width: 3px;

      @media (max-width: 720px) {
        display: block;
        height: 80px;
      }
    }

    a {
      width: 125px;
      text-align: center;
    }

    .link {
      margin-left: 30px;
      margin-top: 20px;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        display: flex;
        flex-direction: column;
        align-items: center
      }

      p {
        width: 125px;
      }

      .practice {
        font-size: 20px;
        width: 100%;
      }

      .label3 {
        font-size: 20px;
      }

      @media (max-width: 720px) {
        margin-top: 0;
        margin-left: 0;
      }
    }

    img {
      height: 80px;
      display: block;
      margin-bottom: 5px;

      @media (max-width: 720px) {
        display: inline-block;
        height: 60px;
        margin-bottom: 0;
      }
    }
  }
}

