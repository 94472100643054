#TeachOneMoreResources {
  height: 100vh;
  color: #d85c3c;
  width: 100%;


  .teachMoreContent {
    padding-top: 85px;
    padding-left: 390px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;

    a {
      margin-top: 20px;
      font-size: 18px;
      cursor: pointer;
      overflow-wrap: break-word;
    }

    @media (max-width: 720px) {
      padding-top: 130px;
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      text-align: left;
      font-size: 28px;
      font-weight: bold;
    }
  }
  .cmeContainer {
    margin-left: 310px;
    padding-top: 45px;
    @media (max-width: 720px) {
      display: flex;
      justify-content: center;
      margin-left: 0;
      padding-top: 30px;
    }
  }

  .claimCME {
    $cmeblue: #74acc4;
    border: 1px solid $cmeblue;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    color: $cmeblue;
    width: 150px;
    height: 30px;

    a {
      margin-top: 7px;
      font-size: 14px;
      font-style: normal;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .arrow-right {
      margin-left: 5px;
      width: 0;
      height: 0; 
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid $cmeblue;
    }
  }
}
