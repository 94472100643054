.NavMenu {
  padding-top: 20px;
  padding-right: 20px;
  text-align: right;
  color: #747474;
  box-sizing: border-box;
  height: 139px;

  @media (max-width: 720px) {
    display: none;
  }

  .navlinks {
    img {
      height: 25px;
    }
    
    a {
      vertical-align: middle;
      padding-left: 20px;
      font-size: 12px;
      font-weight: bold;
      color: #747474;
      display: inline-block;
    }
  }
}