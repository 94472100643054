#SeeOne {
  height: 100vh;
  width: 100%;
  color: white;
  font-size: 20px;

  .mobileOnly {
    text-align: center;
    @media (min-width: 720px) {
      display: none;
    }

    display: block;
    margin-top: 130px;
    color: #704c7c;
    font-size: 20px;
    font-weight: bold;
    img {
      height: 80px;
    }
  }

  .pdfPageClass {
    margin-left: 100px;
    z-index: 100;
  }

  .pdfPage1Class {
    margin-left: 270px;
    z-index: 100;
  }

  .pdfClass {
    border: 2px solid black;
    z-index: 100;
  }

  .pdfOutlineClass {
    border: 2px solid red;
    z-index: 100;
  }


  .interstitial {
    width: 586px;
    height: 324px;
    background-color: black;
    position: fixed;
    top: 22vh;
    left: 370px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    cursor: pointer;

    @media (max-width: 720px) {
      left: 0;
      width: 100vw;
      height: 320px;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 30px 10px 0;
      text-align: center;
      @media (max-width: 400px) {
        margin-top: 12px;
        font-size: 16px;
      }
    }

    h2.line2 {
      margin-top: 5px;
    }

    p {
      margin: 12px 10px 0;
      margin-top: 12px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;

      @media (max-width: 375px) {
        line-height: 15px;
      }
    }

    p.click {
      margin-top: 22px;
      font-size: 14px;
      font-weight: bold;
      line-height: 15px;
    }

    .images {
      display: flex;
      flex-direction: row;
      justify-content: center;

      p {
        font-size: 14px;
      }

      .original {
        color: #98dbda;
      }

      .generic {
        color: #b9afd7
      }
    }

    .imageWithLabel {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      margin: 5px 20px;
      height: 125px;
    }
  }

  .interstitial.hide {
    display: none;
  }

  .videos {
    text-align: center;
    margin-top: 50px;
    margin-left: 370px;
    color: #704c7c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: right;
    width: 100%;

    @media (max-width: 720px) {
      margin-top: 0;
      color: #704c7c;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }

    .mobileDots {
      display: none;
      width: 3px;

      @media (max-width: 720px) {
        display: block;
        height: 80px;
      }
    }

    .video.playing {
      position: fixed;
      top: 22vh;
      z-index: 1;
    }

    .video {
      margin: 0 10px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 720px) {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .label {
        margin-top: 7px;
        font-weight: bold;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;

    @media (max-width: 720px) {
      align-items: center;
    }
  }

  .overview {
    width: 100%;
    font-size: 18px;
    margin-top: 45px;
    //margin-left: 710px;
    margin-left: 930px;
    color: #704c7c;
    font-weight: bold;

    @media (max-width: 720px) {
      text-align: center;
      height: 20px;
      margin: 25px auto;
    }
  }

  .overview.hidden {
    display: none
  }
 
  .nextSection {
    width: 100%;
    margin-top: 35px;
    margin-left: 1000px;
    font-weight: bold;
    color: #704c7c;

    img {
      height: 30px;
    }

    @media (max-width: 720px) {
      display: block;
      width: auto;
      margin: auto;
    }
  }

  .arrow-right {
    margin-left: 5px;
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid #704c7c
  }
}