#Introduction {
  //height: 100%;
  min-height: 100vh;
  color: white;
  background-color: #74acc4;

  h1 {
    padding-top: 170px;
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0.1925em;

    @media (max-width: 720px) {
      padding-top: 25px;
      text-align: center;
      width: 100%;
      margin: 0 5px;
    }
  }

  h3 {
    padding-top: 20px;
    font-weight: bold;
  }

  .bestViewed {
    font-style: italic;
    display: flex;
    padding-top: 30px;
    padding-left: 30px;
    flex-direction: row;
    align-items: center;
    img {
      height: 24px;
      margin-right: 5px;
    }
    @media (max-width: 720px) {
      display: none;
    }
  }

  .introContent {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    //align-items: flex-start;
    justify-content: flex-start;
    
    @media (min-width: 720px) {
      height: 100%;
    }
  }

  .overview {
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 325px;

    @media (max-width: 720px) {
      margin-left: unset;
    }
  }

  .notFooters {
    width: 100%;
    min-height: 542px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    flex-shrink: 0;
    background-color: #74acc4;

    @media (max-width: 720px) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  .introText {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    align-items: flex-start;
    @media (max-width: 720px) {
      padding: 20px;
      box-sizing: border-box;
      text-align: left;
    }

    .earnCredits {
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: bold;
    }

  }

  ol {
    list-style: decimal;
    margin-left: 17px;
  }

  li {
    width: 100%;
    font-weight: 400;
    margin-top: 20px;
    padding-left: 20px;
    @media (max-width: 720px) {
      margin-top: 10px;
    }
  }

  .cmeOrNot {
    background-color: #74acc4;
    padding-top: 20px;
    $orange: #f4ac45;
    font-weight: bold;
    display: flex;
    align-self: center;
    justify-content: center;

    @media (max-width: 720px) {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .maybeLater {
      height: 37px;
      background-color: white;
      color: $orange;
      width: 150px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid $orange;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      @media (max-width: 720px) {
        margin-left: 0;
      }

      .homeLink {
        display: flex;
        flex-direction: row;
      }

      .arrow-right {
        margin-top: 1px;
        margin-left: 16px;
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 10px solid $orange;
      }

    }

    .cme {
      float: left;

      img {
        height: 35px;
        border: 1px solid white;
        margin-top: 1px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    img {
      height: 37px;
      outline: 1px solid $orange;

      @media (max-width: 720px) {
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .footers {
    //height: 100%;
    width: 100%;
    text-align: center;
    align-self: flex-end;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    background-color: #74acc4;
    align-items: center;
    //flex-shrink: 0;

    button {
      font-style: italic;
      font-size: 18px;
      background-color: #74acc4;
      color: white;
    }

    @media (max-width: 720px) {
      bottom: 5px;
      left: 5px;
    }

    .logos {
      display: flex;
      flex-direction: row;
      margin: 10px;
      background-color: #74acc4;
      border-top: 2px solid white;
      justify-content: center;
      flex-shrink: 0;

      img {
        margin: 20px 10px 10px 10px;
        height: 50px;

        @media (max-width: 720px) {
          margin: 20px 10px 10px 10px;
          height: 18px;
        }
      }
    }
  }

  .footnote {
    margin-bottom: 10px;
  }

  #disclosure {
    background-color: #74acc4;
    padding: 0 20px;
    margin-top: 20px;
    font-style: normal;
    text-align: left;

    .statements {
      margin-top: 20px;
    }

    strong {
      font-weight: bold;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      margin-top: 10px;
      text-align: left;
      margin-right: 20px;
    }

    ul {
      background-color: #74acc4;
      margin-top: 20px;
    }

    li {
      margin-top: 5px;
    }

    .references {
      margin-top: 20px;
      margin-right: 20px;
      
      a {
        text-decoration: underline;
      }
    }

  }

  .disclaimerButton {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 270px;
    background-color: #74acc4;
    font-size: 18px;
    text-decoration: underline;
    color: white;
    font-family: SeravekWebBasic;
    cursor: pointer;

    @media (max-width: 720px) {
      margin-left: unset;
    }
  }

  .disclaimerText {
    background-color: #74acc4;
    padding-bottom: 10px;
    width: 100%;

    @media (max-width: 720px) {
      width: 95%;
    }
  }

  .disclaimerLink {
    cursor: pointer;
  }
  
}