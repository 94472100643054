.MobileNav {
  color: black;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;

  .border {
    height: 90px;
    border-bottom: 1px solid grey;
    box-sizing: border-box;
    width: 95%;
    margin-left: 2.5%;
  }

  .title {
    font-size: 30px;
    color: grey;
    position: fixed;
    left: 110px;
    top: 35px;

    @media (max-width: 360px) {
      left: 90px;
      font-size: 26px;
    }
  }

  @media (min-width: 720px) {
    display: none;
  }

   /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: white;
    transition: color 0.3s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: black;
  }

  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: black;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .menu-item {
    color: black;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0.0, 0, 0.3);
  }

}