.QuizNav {
  margin-top: auto;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  display: flex;
  color: #d85c3c;
  text-align: center;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 15px;

  @media (max-width: 720px) {
    padding-bottom: 5px;
    margin-bottom: 5px;
    flex-direction: column;
  }

  .row1 {
    padding-bottom: 10px;
  }

  .row2 {
    margin-top: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .qnum {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 4px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    border: 1px solid #d85c3c;
    border-radius: 20px;
    cursor: pointer;
  }

  .mobile {
    width: 100%;
    display: none;

    @media (max-width: 720px) {
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      justify-content: space-evenly;
    }
  }

  .desktop {
    display: none;
    @media (min-width: 720px) {
      width: 100%;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
      justify-content: flex-start;
    }
  }

  .true {
    background-color: #d85c3c;
    color: white;
  }
}