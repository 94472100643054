$orange: #d85c3c;

.TeachOneQuiz {
  background-color: white;
  text-align: left;
  color: $orange;
  margin-left: 370px;
  font-size: 16px;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height:  calc(100vh - 139px);

  p {
    font-weight: bold;
    margin-bottom: 5px;
  }

  input[type='radio'] {
    display: block;
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid $orange;
    border-radius:50%;
    margin-right: 8px;
    outline:none;
    box-shadow:0 0 5px 0px $orange inset;
  }

  input[type='radio']:hover {
      box-shadow:0 0 5px 0px $orange;
  }

  input[type='radio']:before {
      content:'';
      display:block;
      width:20px;
      height:100%;
      border-radius:50%;    
  }

  input[type='radio']:checked:before {
      background: $orange;
  }

  label {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-bottom: 15px;
  }


  @media (max-width: 720px) {
    margin-top: 00px;
    margin-left: 0px; 
  }

  .question {
    margin-top: 40px;
    padding-left: 5px;

    @media (max-width: 720px) {
      margin: 0 7px 0 10px;
    }
  }


  .questionPrefaceHeader {
    margin-top: 50px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
  }

  .hint {
    color: #808080;

  }

  .mobileOnly {

    @media (min-width: 720px) {
      display: none;
    }

    .questionPrefaceTeach {
      font-size: 26px;
      text-align: left;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 720px) {
    .questionPrefaceHeader {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }


  .questionText {
    margin: 20px 15px 10px 0;
  }

  .selectAnswer {
    margin-top: 20px;
    margin-left: 20px;

    @media (max-width: 720px) {
      margin-left: 0px;
    }
  }

  input {
    margin-left: 20px;

    @media (max-width: 720px) {
      margin-left: 0;
    }
  }

  a {
    color: #74acc4;
    font-weight: bold;
  }

  #showShowAnswer {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 20px 0px 10px 370px;
    font-weight: bold;
    font-size: 13px;
    color: $orange;
    background-color: white;
    cursor: pointer;
    @media (max-width: 720px) {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin: 15px 10px 10px 0;
    }
  }

  #next {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 13px;
    color: $orange;
    background-color: white;
    justify-content: flex-start;
    margin-left: 437px;
    cursor: pointer;
    -webkit-box-pack: end;
    -ms-flex-pack: end;

    @media (max-width: 720px) {
      justify-content: flex-end;
      margin: 15px 10px 10px 0;
    }
  }


  button {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .arrow-right {
    margin-left: 5px;
    margin-bottom: 3px;
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid $orange;
  }

  .showAnswer {
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ul {
      list-style: disc;
      margin-left: 20px;
    }

    .referenceSection {
      margin-top: 25px;
      margin-bottom: 10px;
      font-weight: normal;

      @media (max-width: 720px) {
        margin-top: 20px;
      }

      .references {
        padding-top: 10px;
        margin-right: 20px;
        a {
          font-weight: normal;
        }
        p {
          font-weight: normal;
        }

        @media (max-width: 720px) {
          margin-right: 10px;
        }
      }

      .reference {
        margin-top: 5px;
      }

      .showShowReferences {
        font-weight: bold;
        display: -webkit-box; 
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex; 
        display: flex;
        align-items: center;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        cursor: pointer;
      }
     
      .arrow-right {
        margin-left: 5px;
        margin-bottom: 0px;
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 10px solid grey;
      }
     
      .arrow-down {
        width: 0; 
        height: 0; 
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid grey;
        margin-right: 2px;
      }
    }

    .answer {
      font-weight: bold;
      font-size: 22px;
      margin-top: 20px;
      margin-bottom: 10px;
      white-space: pre-line;
    }

    .answerText {
      white-space: pre-line;
      width: 100%;
    }
  }

}

