#Sidebar {
  height: 100%; 
  width: 360px; 
  position: fixed;
  top: 0; 
  left: 0;
  overflow-x: hidden; 
  display: float;
  z-index: 2;

  @media (max-width: 720px) {
    display: none;
  }

  img {
    margin-left: 150px;
    margin-top: 200px;
    height: 80px;
    border-radius: 50px;
    float: left;
  }

  img.DoOne {
    margin-left: 200px;
  }

  img.SeeOne {
    margin-left: 190px;
  }

  .title {
    text-align: right;
    margin-right: 10px;
    margin-top: 220px;
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: bold;
    color: white;
  }

  .title.SeeOne {
    margin-right: 10px;
  }

  .arrow-right {
    margin-left: 5px;
    width: 0;
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid white;
  }

  .subtitle {
    display: flex;
    align-items: flex-end;
    text-align: justify;
    margin-top: 35px;
    margin-left: 110px;
    margin-right: 5px;
    font-weight: bold;
    color: white;
    font-size: 13px;
  }

  .subnav {
    text-align: right;
    margin-right: 10px;
    font-weight: normal;
    font-size: 14px;
    color: white;

    img.teachLinks {
      margin: 0;
    }

    p {
      margin-top: 5px;
      font-weight: bold;
    }

    .practice {
      font-size: 20px;
      font-weight: bold;
    }

    .teachSubNav {
      .headerLink {
        font-size: 16px;
      }

      .disabled {
        color: #F4D0B9;
      }
    }

    .headerLink {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
    }

    .resourceLink {
      margin-top: 5px;
    }

    .doOneSubNav {
      .disabled {
        color:  #d8e4bc;
      }
    }
  }
}

.Sidebar.SeeOne {
  background-color: #704c7c;
}

.Sidebar.DoOne {
  background-color: #80ac3c;
}

.Sidebar.TeachOne {
  background-color: #d85c3c;
}