#TeachOneCongrats {
  height: 100vh;
  color: #d85c3c;
  width: 100%;

  .desktopOnly {
    @media (max-width: 720px) {
      display: none;
    }
  }

  .mobileOnly {
    @media (min-width: 720px) {
      display: none;
    }
  }

  .teachCongratsContent {
    padding-top: 83px;
    padding-left: 390px;

    @media (max-width: 720px) {
      padding-top: 10px;
      padding-left: 15px;
    }

    h2 {
      text-align: left;
      font-size: 22px;
      font-weight: bold;

      @media (max-width: 720px) {
        text-align: center;
        margin-top: 120px;
      }
    }

    .teachLink {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 9px;
        font-weight: bold;
        text-align: center;
      }
       
      p.nextRow {
        margin-top: 0;
      }
      
    }

    .teachLinks {
      display: flex;
      flex-direction: row;

      @media (max-width: 720px) {
        flex-direction: column;
      }
      img {
        height: 80px;
        margin: 15px 30px 0;

        @media (max-width: 720px) {
          height: 70px;
          margin: 30px 0px 0;
        }

        @media (max-width: 360px) {
          height: 60px;
          margin: 0 0px;
        }

      }
    }
    .cmeContainer {
      margin-left: 310px;
      padding-top: 45px;
      @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        margin-left: 0;
        padding-top: 30px;
      }
    }

    .claimCME {
      $cmeblue: #74acc4;
      border: 1px solid $cmeblue;
      border-radius: 6px;
      font-weight: bold;
      font-size: 14px;
      color: $cmeblue;
      width: 150px;
      height: 30px;

      a {
        margin-top: 7px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      .arrow-right {
        margin-left: 5px;
        width: 0;
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid $cmeblue;
      }
    }
  }
}